import React from 'react';
import {Trans} from "gatsby-plugin-react-i18next";
import ReactVideo from "../video-player/ReactVideo";

const CustomerVideo = ({ title, copy, vidURL, poster }) => {
  const handleVideoEnd = (player) => {
    player.currentTime = 0;
    player.load();
  }
  return(
      <div className="container">
        <div className={'grid-container'}>
          <h2><Trans>{title}</Trans></h2>
          <p><Trans>{copy}</Trans></p>
        </div>
        <div className="video-holder">
          <ReactVideo
              vURL={vidURL}
              poster={poster} />
        </div>
      </div>
  )
}
export default CustomerVideo;